<template>
    <Div>
        <el-button type="primary" size="large" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加</el-button>
        <div>
            <el-table :data="tableData"  :key="index" style="width: 100%;margin-bottom: 20px;" row-key="t_id" :header-cell-style="{background:'#f9f9f9'}" stripe default-expand-all
            v-loading="loading" >

                <el-table-column prop="t_id" label="ID" width="55"></el-table-column>
                <el-table-column prop="tname" label="类型名"></el-table-column>
                <el-table-column prop="v_id" label="类型值"></el-table-column>

                <el-table-column  label="操作">
                    <template #default="scope">
                        <el-button type="primary" size="mini" icon="Edit" @click="showDialog(2,scope.row)">修改</el-button>
                        <el-button type="danger" size="mini" icon="Delete" @click="delData(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 添加修改 -->
            <el-dialog v-model="dialog" :title="title">
                <el-form :model="form" label-width="120px" size="large">
                    <el-form-item label="类型名">
                        <el-input v-model="form.tname" placeholder="请输入类型名" />
                    </el-form-item>
                    <el-form-item label="类型值">
                        <el-input v-model="form.v_id" placeholder="请输入类型值" />
                    </el-form-item>

            
                    
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button size="default" @click="dialog = false">取消</el-button>
                        <el-button size="default" type="primary" @click="submitForm()">确认</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </Div>
</template>
<script>
import { toRefs, reactive } from "vue";
import { TypeList, TypeSave, TypeDel } from "@/network/type";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
    name: "TypeList",
    setup() {
        
        const state = reactive({
            tableData:[],
            dialog : false,
            parent_show : true,
            src_show : true,
            title : "添加",
            form: {
                t_id:0,
                tname: "",
                v_id:"",

            },
        });
        TypeList().then((e)=>{
            state.tableData = e;
        } )
        const showDialog = (e,row)=>{
            state.dialog = true;
            if(e == 1){
                state.form = {
                    t_id:0,
                    tname: "",
                    v_id:"",
                };
                state.title = "添加";
            }else{
                state.title = "修改";
                state.form = {
                    t_id: row.t_id,
                    tname: row.tname,
                    v_id: row.v_id
                };
               
            }
        }
        const submitForm = ()=>{
            TypeSave(state.form).then( (e)=>{
                if (e != 1) {
                    ElMessage({ message:'成功', type: "success" });
                    TypeList().then( (e)=>{
                        state.tableData = e;
                        state.dialog = false;
                    } )
                    
                }
            } )
        }
        const delData = (row={})=>{
            ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "删除："+row.tname, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then( ()=>{
                TypeDel({t_id:row.t_id}).then( (e)=>{
                    if (e != 1) {
                        ElMessage({ message:'成功', type: "success" });
                        TypeList().then( (e)=>{
                          state.tableData = e;
                        } )
                    }
                } )
            } ).catch(() => {
                ElMessage({
                    type: "info",
                    message: "取消删除"
                })
            })
        }
        return {
            ...toRefs(state),
            showDialog,
            submitForm,
            delData
        };
    }
};
</script>