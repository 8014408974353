<template>
    <div>
        <div class="searchbar">
			<el-row class="pt20">
				<el-col :span="12">
					<el-button type="primary" size="large" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加</el-button>
                    <el-row class="mb-4" style="margin-bottom: 10px;margin-top: -10px;">
                        <el-button type="success" plain  v-for="(item, index) in bigcate" :key="index" @click="bcate(item.id)">
                            <span v-if="ccate == item.id" style="color:red;">{{item.catname}}</span><span v-else>{{item.catname}}</span>
                        </el-button>
                    </el-row>
				</el-col>
                
				<el-col :span="12" style="text-align: right;">
                    
					<el-form :inline="true" :model="search" class="demo-form-inline">

						<el-form-item label="">
							<el-input v-model="search.value" size="mini" placeholder="请输入标题关键字"></el-input>
						</el-form-item>

						<el-form-item>
							<el-button type="primary" size="mini" @click="onSearch">查询</el-button>
                            <el-button type="info" size="mini" @click="delSearch">取消查询</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
		</div>

		<el-table :data="tableData"  :key="index" style="width: 100%;margin-bottom: 20px;" row-key="n_id" :header-cell-style="{background:'#f9f9f9'}" stripe default-expand-all
		 v-loading="loading" >

			<el-table-column type="selection" width="55">
			</el-table-column>
			<el-table-column prop="n_id" label="ID" width="55"></el-table-column>
            <el-table-column prop="title" label="标题"></el-table-column>
			<el-table-column prop="npic" label="图片" >
				<template #default="scope">
					<el-image v-if="scope.row.npic" :src="base + scope.row.npic" fit="fill" style="margin-top: 5px;width: 30px;height: 30px;"></el-image>
				</template> 
			</el-table-column>

            <el-table-column label="多图" >
              <template #default="scope">
                <template v-for="(it, i) in scope.row.test_img_many_s" :key="i">
                  <el-image :preview-src-list="[it.url]" :src="it.url" style="width: 30px;height: 30px;" preview-teleported />
                </template>
              </template>
            </el-table-column>
            
			<el-table-column prop="caten" label="所属分类" width="100"></el-table-column>

			<!-- <el-table-column prop="time" label="创建时间"></el-table-column> -->
			<el-table-column  label="操作">
				<template #default="scope">
                    <el-button type="primary" size="mini" icon="Edit" @click="showDialog(2,scope.row)">修改</el-button>
                    <el-button type="danger" size="mini" icon="Delete" @click="delData(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>

        <el-pagination layout="total,prev, pager, next, jumper,  size" background :total="total" :page-size="limit" @current-change="CurrentChange" />
        <!-- 添加修改 -->
        <el-dialog v-model="dialog" :title="title" :fullscreen="sf">
            <suo-fang  v-on:customEvent="handleCustomEvent"></suo-fang>
            <el-form :model="form" label-width="120px" size="large">
                <el-form-item label="标题">
                    <el-input v-model="form.title" placeholder="请输入标题" />
                </el-form-item>
                
                

                <el-form-item label="归属" v-if="parent_show">
                <el-tree-select
                    v-model="form.cate_id"
                    :data="cateData"
                    :normalizer="normalizer"
                    :render-after-expand="false"
                    :props="{ value: 'id',label: 'catname'}"
                    placeholder="选择归属类别"
                    style="width:100%"
                    @change="ftype()"
                    :check-strictly="false"
                    
                    
                />
                </el-form-item>

                <el-form-item label="关键词">
                    <el-input v-model="form.keys" placeholder="请输入关键词" />
                </el-form-item>
                <el-form-item label="描述">
                    <el-input
                        v-model="form.desc"
                        :autosize="{ minRows: 2, maxRows: 4 }"
                        type="textarea"
                        placeholder="请输入描述"
                    />
                </el-form-item>
            

                <el-form-item label="图片:" :label-width="formLabelWidth" size="large" prop="npic">
                    <div style="display: flex;">
                        <el-upload class="avatar-uploader" :action="upload_img" :show-file-list="false" :data="{ Authorization: token }" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="form.npic" :src="base + form.npic" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                        </el-upload>
                        <el-icon v-if="form.npic"  @click="delnpic()"><Delete /></el-icon>
                    </div>
                    
                    
                </el-form-item>

                <el-form-item label="PDF:" :label-width="formLabelWidth" size="large" v-if="type == 6">
                    <el-input v-model="form.pdf" disabled placeholder="pdf文件路径" />
                    <el-upload class="upload-demo"  :limit="1" :action="upload_img" :show-file-list="false" :data="{ Authorization: token }" :on-success="handleAvatarSuccessPDF" :before-upload="beforeAvatarUpload">
                        <template #trigger>
                        <el-button type="primary">上传PDF</el-button>
                        </template>
                    </el-upload>
                </el-form-item>
                
                <el-form-item label="多图片:" :label-width="formLabelWidth" size="large" prop="mpic" v-if="type == 1">
                    <el-upload
                    v-model:file-list="imgList"
                    :action="upload_img"
                    list-type="picture-card"
                    :data="{ Authorization: token }"
                    :on-success="UploadImages"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    >
                    <el-icon><Plus /></el-icon>
                    </el-upload>
                    <el-dialog v-model="dialogVisible">
                        <img w-full :src="dialogImageUrl" alt="Preview Image" />
                    </el-dialog>
                </el-form-item>
                <el-form-item label="产品规格" v-if="type == 1">
                    <el-input
                        v-model="form.cpinfo"
                        :autosize="{ minRows: 4, maxRows: 8 }"
                        type="textarea"
                        placeholder="输入#号进行换行"
                    />
                </el-form-item>

                <!-- <el-form-item label="内容">
                    <el-input v-model="form.content" placeholder="请输入内容" />
                </el-form-item> -->
                <quill-editor 
                    class="ql-editor"
                    v-model:content="form.content" 
                    contentType="html"
                    ref="myQuillEditor"
                    :options="editorOption"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @change="onEditorChange($event)"
                />

            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="default" @click="dialog = false">取消</el-button>
                    <el-button size="default" type="primary" @click="submitForm()">确认</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
   
</template>
<script>
import { ElMessage, ElMessageBox} from "element-plus";
import { toRefs,reactive, onMounted  } from "vue";
import { NewsLists, NewsSave, NewsDel, NewsType, BigCate, CateLists } from "@/network/news";
import { UpPic} from "@/network/index";
//编辑器
import { Quill, QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ImageUploader from "quill-image-uploader";
Quill.register("modules/imageUploader", ImageUploader);
import { ImageDrop } from 'quill-image-drop-module'  //图片拖拽 
Quill.register('modules/imageDrop', ImageDrop)
import BlotFormatter from 'quill-blot-formatter'  //图片缩放
Quill.register('modules/blotFormatter', BlotFormatter)
import htmlEditButton from "quill-html-edit-button"; //源代码编辑
Quill.register({"modules/htmlEditButton": htmlEditButton});

import SuoFang from '@/components/SuoFang.vue';
export default {
    name: "NewsList",
    components: {
        QuillEditor,Quill,ImageDrop,SuoFang
    },
    setup() {
        onMounted(()=>{
            checkDeviceType();
        });
        const base =(process.env.VUE_APP_PUBLIC_PATH); // 获取基本URL
        const state = reactive({
            sf:false,
            tableData:[],
            cateData:[],
            imgList: [],
            dialog : false,
            parent_show : true,
            src_show : true,
            title : "添加",
            total: 1,
            tpage: 1,
            limit: 10,  //设置分页数
            //多图上传  图片弹框放大
            dialogImageUrl: "",
            dialogVisible: false,
            type:0,
            bigcate:"",
            ccate:"",
            form: {
                cate_id:0,
                caten: "",
                n_id : 0,
                content : "",
                title: "",
                time:"",
                npic:"",
                mpic:""

            },
            search : {
                value : '',
            },
            upload_img: base + "/admin/Index/upload_img",
            token: window.localStorage.getItem("token"),
        });
        CateLists().then((res)=>{
            // const menu = { id: 0, catname: '选择分类', children: [] };
            // menu.children = res;
            state.cateData = res;
        } );
        NewsLists().then((res)=>{
            state.tableData = res.lists;
            state.total = res.total;
            // console.log(res)
        } );
        BigCate().then((res)=>{
            // console.log(res);
            state.bigcate = res;
        });
        
        // 翻页方法
        const CurrentChange = (e)=>{
            console.log('e',e)
            
            if(state.ccate){
                NewsLists({p:e,value:state.ccate,type:2}).then((res) => {
                        state.tableData = res.lists;
                        state.tpage = e;
                });

            } else{
                NewsLists({p:e,value:state.search.value}).then((res) => {
                        state.tableData = res.lists;
                        state.tpage = e;
                });

            }
            
        }
        // 搜索
        const onSearch = () => {
            // console.log('ss',state.search.value)
            NewsLists({value:state.search.value,p:state.tpage}).then((res) => {
                state.ccate = '';
                state.tableData = res.lists;
                state.total = res.total;
            });
        }
        const delSearch = () => {
            state.search.value = ''
            NewsLists({p:state.tpage}).then((res) => {
                state.tableData = res.lists;
                state.total = res.total;
            });
        }
        //ADD AND SAVE
        const showDialog = (e,row)=>{
            // console.log(row)
            state.dialog = true;
            if(e == 1){
                state.form = {
                  n_id:0,
                  title: "",
                  cate_id : "",
                  content : "",
                  npic:"",
                  mpic:"",
                  keys:"",
                  desc:"",
                  pdf:"",
                  cpinfo:""
                  
                };
                state.imgList = [];
                state.title = "添加";
                state.type = 0;
                state.form.content = '<p>';
            }else{
                ftype();
                state.title = "修改";
                state.form = {
                    n_id: row.n_id,
                    cate_id: row.cate_id,
                    title: row.title,
                    content: row.content,
                    npic:row.npic,
                    mpic:row.mpic,
                    keys:row.keys,
                    desc:row.desc,
                    pdf:row.pdf,
                    cpinfo:row.cpinfo
                };
                if (state.form.mpic) {
                    state.imgList = row.test_img_many_s.map((item) => {
                        return { uid: item.uid, url: item.url, data: item.data };
                    });
                    state.form.mpic = row.test_img_many_s.map((item) => {
                        return item.data;
                    });
                    } else {
                        state.imgList = [];
                   }
                //防止内容为空，编辑器返值错误
                if(!row.content){
                    state.form.content = '<p>'
                };   
            }
        }
        const submitForm = ()=>{
            
            if(state.form.mpic){
                state.form.mpic = state.form.mpic.join(";");
            }
            NewsSave(state.form).then( (e)=>{
                if (e != 1) {
                    
                    ElMessage({ message:'成功', type: "success" });
                    
                    if(state.ccate){
                        bcate(state.ccate);
                        state.dialog = false;
                    } else if(state.search.value){
                        onSearch(state.search.value);
                        state.dialog = false;
                    } else{
                        NewsLists({p:state.tpage}).then( (e)=>{
                            state.tableData = e.lists;
                            state.total = e.total;
                            state.dialog = false;
                            
                        } )
                    }
                    
                   
                    
                    
                }
            } )
        }
        //上传
         // 管理员token，上传图片接口需要
         const token = window.localStorage.getItem("token");
        //单图片上传
        const beforeAvatarUpload = (file) => {
            const isJPG = file.type === "image/png" || "image/jpg" || "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isJPG) {
                ElMessage({ message: "上传头像图片只能是 JPG/PNG/JPEG 格式!", type: "error" });
            }
            if (!isLt2M) {
                ElMessage({ message: "上传头像图片大小不能超过 3MB!", type: "error" });
            }
            return isJPG && isLt2M;
        };
        const handleAvatarSuccess = (response, uploadfile) => {
            
            state.form.npic = response.data;
            state.form.img = URL.createObjectURL(uploadfile.raw);
            // console.log(state.form.img)
        };
        const delnpic =() =>{
            state.form.npic = null;
        };
        //单文件pdf上传成功
        const handleAvatarSuccessPDF = (response, uploadfile) => {
            state.form.pdf = response.data;
        };
        //多图文件上传
        const UploadImages = (response, uploadfile) => {
                state.form.mpic = state.imgList.map((item) => {
                    if (item.data) {
                        return item.data;
                    } else {
                        return item.response.data;
                    }
                });

                // console.log(state.form.mpic,'&&',response);
       };
                const handlePictureCardPreview = (uploadFile, uploadFiles) => {
                    state.dialogImageUrl = uploadFile.url;
                    state.dialogVisible = true;
                };
                const handleRemove = (uploadFile, uploadFiles) => {
                    state.form.mpic = uploadFiles.map((item) => {
                        if (item.raw != null) return item.response.data;
                        else return item.data;
                    });
                    console.log(state.form.mpic);
        };
        const delData = (row={})=>{
            ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "删除："+row.title, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then( ()=>{
                NewsDel({n_id:row.n_id}).then( (e)=>{
                    if (e != 1) {
                        ElMessage({ message:'成功', type: "success" });
                        if(state.ccate){
                            bcate(state.ccate);
                        } else if(state.search.value){
                            onSearch(state.search.value);
                        } else{
                            NewsLists({p:state.tpage}).then( (e)=>{
                                state.tableData = e.lists;
                                state.total = e.total;    
                            } )
                        }
                    }
                } )
            } ).catch(() => {
                ElMessage({
                    type: "info",
                    message: "取消删除"
                })
            })
        };
        //编辑器
        const editorOption = {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                    ['blockquote', 'code-block'], // 引用  代码块
                    [{ header: 1 }, { header: 2 }], // 1、2 级标题
                    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                    [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                    [{ indent: '-1' }, { indent: '+1' }], // 缩进
                    [{ direction: 'rtl' }], // 文本方向
                    // [{ size: ['12px', false, '16px', '18px', '20px', '30px'] }], // 字体大小
                    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                    [{ font: [false, 'SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }], // 字体种类
                    [{ align: [] }], // 对齐方式
                    ['clean'], // 清除文本格式
                    ['link', 'image', 'video'], // 链接、图片、视频
                ],
                 // 图片拖拽
                 imageDrop: true,
                 // 图片缩放
                 blotFormatter: {
                    // overlay: {
                    //    style: {
                    //        border: '2px solid red',
                    //    }
                    // },
                    toolbar: {
                    mainClassName: 'blot-formatter__toolbar'
                    }
                },
                 // html源代码编辑
                htmlEditButton: {
                    debug: true, // logging, default:false
                    msg: "在这里修改HTML代码，点击完成将替换编辑器的内容", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                    okText: "完成", // Text to display in the OK button, default: Ok,
                    cancelText: "取消", // Text to display in the cancel button, default: Cancel
                    buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
                    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                    prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                    editorModules: {} // The default mod
                },

                 //上传图片存服务器
                imageUploader: {
                    upload: (file) => {
                        
                        return new Promise((resolve, reject) => {

                            var formData = new FormData();
                            formData.append('file', file);
                            formData.append('Authorization',token);
                            UpPic(formData).then((e)=>{
                            
                                // console.log(e,'@@')
                                if(e.code != 1 ){
                                    setTimeout(() => {
                                        resolve(base + e);
                                    }, 500);
                                }

                            });
                            // console.log(resolve,"555",file)
                            
                            
                        });
                    }
                },

            },
            
            placeholder: '请输入内容',
        };
        
        
        // 失去焦点事件
        const onEditorBlur = (quill) => {
            console.log('editor blur!', quill);
        };
        // 获得焦点事件
        const onEditorFocus = (quill) => {
            console.log('editor focus!', quill);
        };
        // 准备富文本编辑器
        const onEditorReady = (quill) => {
            console.log('editor ready!', quill);
        };
        // 内容改变事件
        const onEditorChange = ({ quill, html, text }) => {
            console.log('editor change!', quill, html, text);
            content.value = html;
        };

        //接收子组件数据
        const handleCustomEvent = (data) => {
            state.sf = data; // 处理接收到的数据
        };
        //查是否手机登陆
        const checkDeviceType =() => {
            const userAgentInfo = navigator.userAgent;
            if (/(Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini)/.test(userAgentInfo)) {
                
                state.sf = true; 

            } else {
                state.sf = false; // 若是移动设备则隐藏该div
            }
        };
        //选择分类查类型
        const ftype =()=>{
            
                
                setTimeout(() => {
                    NewsType({n_id:state.form.cate_id}).then((res)=>{
                        state.type = res.type;
                     });
                }, 500);
            
        };
        //选择大类列表
        const bcate =(id)=>{
            state.ccate = id;
            state.search.value = '';
            NewsLists({type:2,value:state.ccate,p:state.tpage}).then((res) => {
                state.tableData = res.lists;
                state.total = res.total;
            });
        };
        return {
            base,
            ...toRefs(state),
            CurrentChange,
            onSearch,
            delSearch,
            showDialog,
            submitForm,
            beforeAvatarUpload,
            handleAvatarSuccess,
            delnpic,
            handleAvatarSuccessPDF,
            delData,
            //多图
            UploadImages,
            handlePictureCardPreview,
            handleRemove,
            //编辑器
            editorOption,
            onEditorBlur,
            onEditorFocus,
            onEditorReady,
            onEditorChange,

            handleCustomEvent,
            ftype,
            bcate

        };
    }
}   
</script>

<style>
.avatar-uploader .avatar {
  width: 78px;
  height: 78px;
  display: block;
}
.el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
 
.el-upload-list--picture-card .el-upload-list__item {
  width: 80px !important;
  height: 80px !important;
}
</style>